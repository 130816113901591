<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      COPYRIGHT  © {{ new Date().getFullYear() }}
      <b-link
        class="ml-25"
        href="https://www.thefiveshotels.com/"
        target="_blank"
        :class="$store.state.appConfig.layout.skin === 'light' ? 'text-light-color' : 'text-dark-color'"        
      >The Fives Hotels & Residences</b-link>
      
      <span class="d-none d-sm-inline-block">, Todos los derechos reservados</span>
    </span>

    <span class="float-md-right d-none d-md-block"> Hecho con
      <feather-icon
        icon="HeartIcon"
        size="21"
        class="text-danger stroke-current mr-1"
      />
      <!-- y a mano
      <b-icon icon="hand-index-thumb" aria-hidden="true"></b-icon> -->
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
}
</script>
<style scoped>
.text-light-color{
  color: black;
}

.text-dark-color{
  color: white;
}
</style>