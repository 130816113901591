<template>
  <b-nav-item-dropdown
    v-if="user"
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user dropdown-text"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav text-right">
        <p class="user-name font-weight-bolder mb-0">{{ fullNameUser  }}</p>
        <div class="user-status canalventa-titulo">
          <span class="">{{ user.canalventa.name }}</span><br>
          <span> {{puntoDeVenta}} </span>
        </div>
      </div>
      <b-avatar
        size="lg"
        variant="light-success"
        :text="user.name.substring(0,1).toUpperCase()+user.lastname.substring(0,1).toUpperCase()"
      />

    </template>
    <b-dropdown-text :class="windowWidth > 575 ? 'd-none' : '' ">
      <p class="user-name font-weight-bolder mb-0">
        {{ user.name }} {{ user.lastname }}
      </p>
      <span class="user-status">
        {{ user.canalventa.name }} <hr>
        <strong>Canal de Venta</strong> <br>
        {{puntoDeVenta}} <hr>
        <strong>Sección del Canal de venta</strong> <br>
        {{user.sectionCanal.name}}
      </span>
    </b-dropdown-text>
    <b-dropdown-divider :class="windowWidth > 575 ? 'd-none' : '' " />

   <!-- <b-dropdown-item
      @click="clearAll"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="Trash2Icon"
        class="mr-50"
        style="color:red;"
      />
      <span>Limpiar todo</span>
    </b-dropdown-item> -->

    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="_logout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>Salir</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>

import { mapMutations, mapState } from 'vuex'

export default {
  
  data() {
    return {
      imgUrl: process.env.VUE_APP_IMG_SRC_API,
      imdDefault: require('@/assets/images/default.jpg'),              
    }
  },
  computed:{
    ...mapState('shop',['puntoventa']),                     
    ...mapState('auth',['user']),
    ...mapState('appConfig',['windowWidth']),    
    fullNameUser(){
      const stringName = this.user.name +' '+ this.user.lastname
      return this.windowWidth > 1300 ? stringName : stringName.substring(0, 12) + '...'        
    },
    puntoDeVenta(){
      if( this.puntoventa && this.user ){
        const puntoDeVenta = this.user.puntosventa.find( pv => pv.id === this.puntoventa )
        return puntoDeVenta.name 
      } else {
        return ''
      }              
    }
  },
  methods: {
    ...mapMutations('auth',['logout',]),
    ...mapMutations('shop',['unsetCanalVenta','unsetPuntoDeVenta','setEmptyCart','unsetLastNameSearch','unsetDataRoom']),
    ...mapMutations('bookings',['resetToInitialState']),
    ...mapMutations('products',['setProducts','setSelectedCategory']),    
    _logout() {
      this.logout()
      this.unsetCanalVenta()
      this.unsetPuntoDeVenta()
      this.setEmptyCart()
      this.unsetLastNameSearch()
      this.unsetDataRoom()
      this.resetToInitialState()
      this.setProducts([])
      this.setSelectedCategory(null)      
      // this.$router.push({ name: 'auth-login' })
      localStorage.removeItem('vuex')
      window.location.reload(true)
      }
    // clearAll(){
    //   this.logout()
    //   this.unsetCanalVenta()
    //   this.unsetPuntoDeVenta()
    //   this.setEmptyCart()
    //   this.unsetLastNameSearch()
    //   this.unsetDataRoom()
    //   this.resetToInitialState()
    //   this.setProducts()
    //   this.setSelectedCategory(null)      
    //   // this.$router.push({ name: 'auth-login' })
    //   localStorage.removeItem('vuex')
    //   window.location.reload(true)
    //   // window.location = window.location.href+'?eraseCache=true'
    // }
  },
}
</script>
<style scoped>
.canalventa-titulo{
  inline-size: 11rem;
  overflow: hidden;
  overflow-wrap: break-word;
}

.canalventa-titulo:hover{
  direction: rtl
}

</style>